.container{
    display: flex;
    flex-direction: row;
    align-items: center;
    
    }
    .left {
        display: flex;
        align-items: center;
    }
    .right{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 2px 0 2px 15px;
    }
    .name{
        margin-top:15px;
        width:90px;
        height:55px;
        border-radius: 5px;
        border: 0px solid grey;
        background-color: rgb(234, 234, 234);
        align-items: center;
    
    
    }
    .description{
        margin-top:15px;
        width:90px;
        height:55px;
        border-radius: 5px;
        border: 0px solid grey;
        background-color: rgb(234, 234, 234);
        align-items: center;
    
    }
    .login-input-icon {
        margin-right: 0.4rem;
      }
    .Modal{
        border-radius: 55px;
    }
    .select{
        /* width:350px; */
        margin-top:15px;
        background-color: rgb(234, 234, 234);
        bottom:16px;
        width: 100%;
    }
    .btn{
        height:55px;
        justify-content: center;
        align-items: center;
        
    }
    .btn1{
        margin:8px 65px
    }
    .btn2{
        margin-top:-40px
    }
    .btn-div {
        height: 55px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
      }