@import "../../index.css";

.reset-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 320px;
  max-width: 80vw;
  min-height: 100vh;
}

.home__icon {
  margin: 20px auto;
  height: auto;
  width: 70%;
}

.home__icon circle,
.home__icon path {
  fill: var(--primary);
}
