@import "../../index.css";

.wrapr {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
}

.msgc {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  color: black !important;
}

.err {
  margin-top: 20px;
  max-width: 300px;
  height: 40vh;
  text-align: center;
}
