.expertise-data {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
    /* overflow: hidden; */

    text-align: center;

    color: #6F7379;


    /* Inside auto layout */

    /* flex: none;
    order: 0;
    flex-grow: 0; */
}

.expertise-pill {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 2px 5px;
    gap: 10px;
    /* overflow: hidden; */
    width: fit-content;
    height: 22px;

    background: #EEEEEE;
    border-radius: 6px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.expertise-container {
    /* margin: 9px 4px; */
    overflow: hidden;
    /* display: flex; */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 2px 5px;
    gap: 10px;
    /* overflow: hidden; */
    width: fit-content;
    /* height: 22px; */
}

.media-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
}

.media-pill {
    margin: 8px 10px;
}

.media-video {
    width: 24px;
    height: 24px;
    border-radius: 3px;
    background-color: transparent;
}

.video-container {
    position: relative;
}    

.video-container img{
    height: 13px;
    width: 12px;
    top : 5px;
    left: 6px;
    position: absolute;
}

[data-theme="light"] .metrics-container {
    box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: flex-start;
padding: 15px 12px;
margin-bottom: 20px;
margin-right: 20px;

/* position: absolute; */
width: 158px;
height: 75px;
/* left: 239px; */
top: 115px;

background: #FFFFFF;
/* border: 1px solid #DFDCDC; */
border-radius: 6px;
box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

/* @media (max-width : 768px) {
    metrics-count {
        width: 100%;
        background-color: #4C94FF;
        color: #FFCD4C;
        font-size: 10px;
    }
} */


/* [class*="col-"] {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .col-1 {width: 8.33%;}
  .col-2 {width: 16.66%;}
  .col-3 {width: 25%;}
  .col-4 {width: 33.33%;}
  .col-5 {width: 41.66%;}
  .col-6 {width: 50%;}
  .col-7 {width: 58.33%;}
  .col-8 {width: 66.66%;}
  .col-9 {width: 75%;}
  .col-10 {width: 83.33%;}
  .col-11 {width: 91.66%;}
  .col-12 {width: 100%;}
} */

[data-theme="dark"] .metrics-container {
    box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: flex-start;
padding: 15px 12px;
margin-bottom: 20px;
margin-right: 20px;

/* position: absolute; */
width: 158px;
height: 75px;
/* left: 239px; */
top: 115px;

background: #1E1E1F;
/* border: 1px solid #424242; */
border-radius: 4px;
}

.metrics-inner-container {
    display: flex;
    flex-direction: column;
    margin-left: 14px;
}
/* .metrics-image {
    display: flex;
    flex-direction: column; 
    margin-right: 15px;
} */

/* [data-theme="light"] .ant-select-selection-placeholder {
    color: #dddddd !important;
  }
  
  [data-theme="dark"] .ant-select-selection-placeholder {
    color: #3b4045 !important;
  } */

  [data-theme="light"] .metrics-count {
    
width: 60px;
height: 24px;

font-family: 'Inter', sans-serif;
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 24px;

color: #000000;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

[data-theme="dark"] .metrics-count {
    
    width: 60px;
    height: 24px;
    
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    
    color: rgba(255,255,255,.85);
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;
    }

.metrics-type {
    
width: fit-content;
height: 17px;

font-family: 'Inter', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
/* identical to box height */


color: #808080;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
}

.acc-type-expert-container{
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
/* padding: 2px 6px; */
/* gap: 4px; */

width: 50px;
height: 22px;

background: #FF4A01;
border-radius: 4px;

}

.acc-type-expert-container:hover {
    background: #ff6d29;
}

.acc-type-expert{
    width: 38px;
height: 18px;

font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 12px;
line-height: 18px;
/* identical to box height */

text-align: center;

color: #FFFFFF;

}


.acc-type-brand-container{
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
/* padding: 2px 6px; */
/* gap: 4px; */

width: 50px;
height: 22px;

background: #FFCD4C;
border-radius: 4px;

}

.acc-type-brand-container:hover {
    background: #ffd56b;
}

.acc-type-brand{
    width: 38px;
height: 18px;

font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 12px;
line-height: 18px;
/* identical to box height */

text-align: center;

color: #FFFFFF;

}


.acc-type-centre-container{
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
/* padding: 2px 6px; */
/* gap: 4px; */

width: 50px;
height: 22px;

background: #4C94FF;
border-radius: 4px;

}

.acc-type-customer-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3% 5%;
    
    background: #9c6bec;
    border-radius: 4px;
}

.acc-type-centre-container:hover {
    background: #5f9fff;
}

.acc-type-centre{
    width: fit-content;
height: 18px;

font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 12px;
line-height: 18px;
/* identical to box height */

text-align: center;

color: #FFFFFF;

}

.acc-type-customer{
    /* width: fit-content; */
/* height: 18px; */
padding: 2%;

font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 12px;
line-height: 18px;
/* identical to box height */

text-align: center;

color: #FFFFFF;
}

.user-type-container {
    width:90px
}
.acc-type-container {
    width:100px;
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
}
