@import "../../index.css";

.crd {
  color: #000000;
  background-color: #ffffff;
  margin: 0 !important;
  border-radius: 6px !important;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

[data-theme="dark"] .crd {
  background-color: var(--darkBg);
}

[data-theme="dark"] .crd:hover {
  box-shadow: 1.5px 1.5px 1px var(--darkFg) !important;
}

/* 
.ant-card-body{
  /* padding: 0 !important; */
/* }  */