.drwr {
  font-family: 'Poppins', sans-serif;
  max-width: 100%;
  min-width: 240px;
  animation-duration: 17s !important;
}

.drwr-split {
  border-left: 1px solid #d3d3d3 !important;

}

.lblc {
  /* border-left: 1px solid #d3d3d3; */
  margin-bottom: 20px;
  padding: 0 0 10px 15px;
}
/* 
.ant-tabs-tab {
  color: #979797;
}

.ant-tabs-tab:hover {
  color: #000000;
}

[data-theme="dark"] .ant-tabs-tab:hover {
  color: #ffffff;
}

.ant-tabs-tab .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000000;
}

[data-theme="dark"] .ant-tabs-tab .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff;
} 

.ant-tabs-ink-bar {
  background: #000000;
}

[data-theme="dark"] .ant-tabs-ink-bar {
  background: #ffffff;
} */