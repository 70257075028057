.detail-analytics .ant-table-cell.ant-table-row-expand-icon-cell{
    border-right: none !important;
}

.analytic-grid h2{
 color: "#ff4a01"

}

.donut-chart canvas{
    width: 100% !important; 
    height: 34.5vh !important;
}

svg{
    position: relative !important;
}

.map-title{
    margin-bottom: 10px !important;
    z-index: 10000;
}

#deckgl-wrapper{
    height: 100vh !important;
}
.exit:hover{
    font-size: 25;
    transform: scale(1.2);
    transition: transform 0.3s ease-in-out, font-size 0.3s ease-in-out; 
}
.popup-cont p{
    margin: 2px;
}

.popup-cont{
    backdrop-filter: blur(10px);

}