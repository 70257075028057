@import "../../index.css";

.ftr {
  height: 6vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 !important;
  background-color: #fff !important;
  bottom: 0 !important;
}

[data-theme="dark"] .ftr {
  background-color: var(--darkBg) !important;
}