.metric-container{
    font-size: 3.5vw;
}


.metric-container,.reload-icon { transition: all .2s ease-in-out; }
.metric-container:hover { transform: scale(1.1); }


.reload-icon:hover{
transition: 0.2s;
transform: rotateZ(50deg);
}

.awssld__timer{
   z-index: -10 !important;
}

.awssld__content{
    display:block !important;
}
.awssld__bullets{
    z-index: 2 !important;
    bottom: 100px !important;
}

.sales-container{
    padding:8% 5%;
    border: 1px solid #D6D6D6;
    border-radius: 12px;
}

.sales-header{
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 22px
}

.sale-title{
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 40px;
    margin-left: 12px;
}

.sale-number{
    min-width: 100%;
    width: fit-content;
       
        font-weight: 600;
        font-size: 52px;
        margin-bottom: 0px;
     
}

.sale-logo{
    width: 50px;
    height: 50px;

}
.sales-count{
    text-align: right;
}

.sales-count p{
    margin: 0;
    color: #1A1F26;
    font-size: 72px;
    font-weight: 600;
}

.number{
    color:black;
    font-style: italic;
    font-size: 90px;


}

.bottom-containers{
    display:flex;
    align-items: center;
    /* border: 1px solid red; */
    justify-content: space-between;
}
.bottom-containers p{
    margin-bottom: 0px;
    
}


.awssld__bullets button{
    margin: 15px !important;
    background-color: #0B0B0B !important;
}


.awssld__bullets .awssld__bullets--active{
    background-color: #FFDF00 !important;
}
