@import "../../index.css";







.sidenav-icon {
  /* height: 1.2rem; */
  font-size: 18px;
  width: 1.1rem;
}
.sidenav-selected path {
  stroke: #4a2173 !important;
}

[data-theme="dark"] .sidenav-icon path {
  stroke: #ffffff;
}

[data-theme="light"] .mnu {
  color: #000000;
  flex: 1;
}

[data-theme="dark"] .mnu {
  flex: 1;
  background-color: var(--darkBg) !important;
  color: #ffffff !important;
}

[data-theme="dark"] .ant-menu-inline,
.ant-menu-vertical-left {
  border-color: var(--darkBg) !important;
}

[data-theme="dark"] .sdb {
  background-color: var(--darkBg) !important;
}

.lgo {
  min-height: 8vh !important;
  /* padding-top: 10px !important; */
  /* padding-left: 18px !important; */
  overflow: hidden;
  font-size: 1rem;
  line-height: 1;
  font-weight: bolder;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

[data-theme="light"] #logout:hover{
background-color: #ffffff;
color: #2B2B2B;
border-radius: 6px;
transition: .3s ease-in;
}

[data-theme="dark"] #logout:hover{
  background-color: #222121;
color: rgb(255, 74, 1) !important;
border-radius: 6px;
transition: .3s ease-in;
}


.dropdwn-mnu {
  align-items: center;
  color: #4f4f4f;
  cursor: pointer;
  display: inline-flex;
  font-size: 15px;
  text-transform: capitalize;
}