.status-container {
  /* Auto layout */
  padding: 2px 5px;
  margin: auto;
  width: 100%;
  height: 100%;

  /* background: #FFE2E2; */
  border-radius: 4px;
}

.status-holder {
  width: 100%;
  /* height: 18px; */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;

  /* color: #FF0000; */
}
