.lyt {
  flex: 1;
  min-height: 100vh;
  margin: 0 !important;
}

.cnt {
  min-height: 100vh !important;
  /* margin-top: 8vh; */
  margin-bottom: 0 !important;
  padding: 10px;
}

[data-theme="dark"] .cnt {
  background-color: #0e1117 !important;
}
