@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,700&display=swap');

:root {
  --primary: #FFDF00;


  
  --darkFg: #0e1117;
  --darkBg: #222121;
}



body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo-uploader .ant-upload,
.uplodr .ant-upload-list-picture-card-container {
  height: 116px !important;
  width: 116px !important;
  border-radius: 8px !important;
}

.badge-rating {
  min-width: 90px;
  cursor: pointer;
  margin: 0 !important;
}
.badge-rating li {
  margin: 0 8px !important;
}

