@import "../../index.css";

.login-wrapper {
  display: flex;
}

.login-one {
  flex: 3;
  overflow: hidden;
  position: relative;
}
.login-one-full {
  background-color: var(--primary);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
  opacity: 0.7;
}
.login-one-semi {
  clip-path: circle(76% at bottom left);
  height: 100%;
  width: 100%;
  background-color: var(--primary);
  opacity: 0.5;
  z-index: 1;
  position: absolute;
}
.login-one-circle {
  clip-path: circle(60% at bottom);
  background-color: var(--primary);
  top: 20%;
  height: 80%;
  width: 110%;
  z-index: 2;
  position: absolute;
}

.login-two {
  flex: 2;
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* align-items: flex-start; */
  justify-content: center;
  padding: 0 6rem;
}

.login-logo {
  /* background: url(../../assets/logo.png) no-repeat; */
  background-repeat: no-repeat;
  filter: drop-shadow(0px 5px 12px rgba(97, 97, 97, 0.25));
  height: 100px;
  width: 100px;
  background-size: contain;
  /* margin-left: -0.5rem; */
}

.login-title {
  margin-top: 1rem;
  color: var(--primary);
  letter-spacing: 0.1rem;
}

.login-input-icon {
  margin-right: 0.4rem;
}

.login-btn {
  width: 100%;
  font-weight: bolder !important;
  border-radius: 8px !important;
  letter-spacing: 0.1rem;
  height: 3rem;
}
.password-toggle-icon{
  position: absolute;
  top: 8px;
  right:10px;
  z-index: 1000;
  cursor: pointer;
}

@media screen and (max-width: 786px) {
  .login-wrapper {
    flex-direction: column;
  }
  .login-one {
    padding-bottom: 50vh;
  }
  .login-two {
    padding: 3rem;
    min-height: 40vh;
  }
}
