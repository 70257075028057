.data-pill{display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 2px 5px;
    gap: 10px;
    /* overflow: hidden; */
    width: fit-content;
    height: 22px;

    background: #EEEEEE;
    border-radius: 6px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;}


    .pill-container {
        /* margin: 9px 4px; */
        /* display: flex; */
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 2px 5px;
        gap: 10px;
        /* overflow: hidden; */
        width: fit-content;
        /* height: 22px; */
    }