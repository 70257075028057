.profile-step-container {
    display: flex;
    width: "fit-content";
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 2px 5px;
    gap: 10px;
    
    /* width: 79px; */
    height: 22px;

    /* background: #FFE2E2; */
    border-radius: 4px;
}

.profile-step-pill {
    /* width: 69px; */

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    text-align: center;

    /* color: #FF0000; */
}

.steps-progress-bar-inactive {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #EEEEEE;
    border-radius: 50%;
    width: 15px;
    height: 15px;
}
.steps-progress-bar-active {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #08A908;
    border-radius: 50%;
    width: 15px;
    height: 15px;
}

.steps-progress-bar img {
    position: absolute;
    Width: 10px;
    Height : 8px;
}