@import "../../index.css";

/* margin for modal */

.tbl {
  margin-top: 15px;
  position: relative;
}

[data-theme="dark"] thead[class*="ant-table-thead"] th {
  text-align: center;
  /* font-weight: 600; */
  font-size: 14px;
  background-color: var(--darkBg) !important;
}

[data-theme="light"] thead[class*="ant-table-thead"] th {
  text-align: center;
  /* font-weight: 600; */
  font-size: 14px;
  /* background-color: var(--lightBg) !important; */
  background-color: #f3f3f3 !important;
}

[data-theme="dark"] thead[class*="ant-table-thead"] th:hover {
  text-align: center;
  background-color: var(--darkFg) !important;
}

.rww {
  cursor: pointer;
}

.ant-table-tbody > .rww:hover > td {
  background-color: #f6f6f6 !important;
}

[data-theme="dark"] .ant-table-tbody > .rww:hover > td {
  background-color: var(--darkBg) !important;
}

[data-theme="light"] .ant-select-selection-placeholder {
  color: #dddddd !important;
}

[data-theme="dark"] .ant-select-selection-placeholder {
  color: #3b4045 !important;
}

.actions .ant-select-selector {
  border: 1px solid #0B0B0B !important;
}

.space-reviews {
  cursor: pointer;
  padding: 2px 2px 2px 2px;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 400;
  border: 1px solid lightgrey;
}
.space-reviews.active{
  border: 1px solid rgb(251, 160, 124);
  background-color: rgb(255, 234, 227);  
  font-weight: 400;
}
.space-reviews.disabled{
  cursor:not-allowed;
  border: 1px solid rgb(236, 234, 234);
  color: rgb(196, 193, 193);
  background-color: rgb(241, 239, 239);
}
.space-reviews span{
  padding: 12px;
}
.tag-img{
  width: 25px;
}

.sort-container{
  background-color: rgb(255, 234, 227);
  border-radius: 5px;
  padding: 1px 4px;
  cursor: pointer;
  color: black;
  border: 1px solid rgb(251, 160, 124);
  font-size: 10px;
}