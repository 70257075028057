
.category-data {
    /* Auto layout */
    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #6F7379;
    padding: 2px 5px;
    width: fit-content;
    height: 22px;
    background: #EEEEEE;
    border-radius: 6px;
}

.category-container {
    margin-left: auto;
    margin-right: auto;
    padding: 2px 5px;
    width: fit-content;
}

.mnu .ant-menu-item.ant-menu-item-only-child{
    padding-left: 30px !important;
}