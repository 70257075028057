@import "../../index.css";

.tbsc > .ant-tabs-card .ant-tabs-content {
  min-height: 120px;
  margin-top: -16px;
}

.tbsc > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  /* padding: 16px; */
  background: #ffffff;
  border-radius: 6px !important;
 
}

.tbsc > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}

#components-tabs-demo-card-top .code-box-demo {
  /* padding: 24px; */
  overflow: hidden;
}

.tbsc > .ant-tabs-card .ant-tabs-tab {
  background: transparent !important;
  border-color: transparent !important;
}

.tbsc > .ant-tabs-card .ant-tabs-tab-active {
  background: rgb(11, 11, 11) !important;
  border-color: #ffffff;
}

[data-theme="dark"] .tbsc > .ant-tabs-card .ant-tabs-tab {
  background: transparent;
  border-color: transparent;
}

[data-theme="dark"] #components-tabs-demo-card-top .code-box-demo {
  background: #000;
}

[data-theme="dark"]
  .tbsc
  > .ant-tabs-card
  .ant-tabs-content
  > .ant-tabs-tabpane {
  background: var(--darkBg);
}

[data-theme="dark"] .tbsc > .ant-tabs-card .ant-tabs-tab-active {
  background: var(--darkBg) !important;
  border-color: var(--darkBg);
}
